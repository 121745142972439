.container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  padding-inline: 15px;
  max-width: 1310px;
}

.slider {
  width: 100%;
  margin-bottom: 6px;
  border-radius: 15px;

  div[class*='arrow-left'],
  div[class*='arrow-right'] {
    position: absolute;
    top: 50%;
    cursor: pointer;
    color: #fff;
    z-index: 1;
    font-size: 60px;
    font-family: monospace;
    filter: drop-shadow(0 0 3px #00000023);
    transition: 0.1s ease-in-out;
    user-select: none;
    opacity: 0.8;

    &:hover {
      filter: drop-shadow(0 0 3px #00000049);
      opacity: 1;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  div[class*='arrow-left'] {
    left: 50px;
    transform: rotate(-90deg) translateY(-50%);
  }

  div[class*='arrow-right'] {
    right: 50px;
    transform: rotate(90deg) translateY(-50%);
  }

  .id {
    position: absolute;
    top: 25px;
    right: 95px;
    color: #fff;
    background: #f7505ce6;
    border-radius: 5px;
    padding: 3px 5px;
    z-index: 1;

    @media (max-width: 768px) {
      top: 12px;
      right: 45px;
      font-size: 13px;
    }
  }

  .heartIcon {
    transform: scale(1.5);
    position: absolute;
    top: 25px;
    right: 50px;
    z-index: 1;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    user-select: none;

    &:hover {
      transform: scale(1.6);
    }

    @media (max-width: 768px) {
      top: 8px;
      right: 8px;
      transform: scale(1.1);
    }
  }

  .FSIcon {
    width: clamp(22px, 3vw, 42px);
    height: clamp(22px, 3vw, 42px);
    position: absolute;
    bottom: 5%;
    right: 5%;
    z-index: 1;
    cursor: pointer;
    opacity: 0.8;
    transition: 0.1s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .soldMask {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(16, 24, 40, 0.6);

    span {
      color: #fcfcfd;
      background: #f7505ce6;
      border-radius: 12px;
      padding: 24px 40px;
      font-weight: 600;
      font-size: 36px;
      user-select: none;
    }
  }
}

.slide {
  width: 100%;
  aspect-ratio: 1.75;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.infoLeft {
  display: flex;
  flex-direction: column;
  width: 55%;
  margin-top: 30px;

  h1 {
    font-size: clamp(18px, 2vw, 35px);
    font-weight: 700;
  }

  h2 {
    font-size: clamp(14px, 1.5vw, 22px);
    color: var(--text_color2);
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px 2%;
    margin-top: 19px;

    li {
      display: flex;
      align-items: center;
      gap: 5px;

      &:nth-of-type(odd) {
        width: 41%;
      }

      span {
        color: var(--text_color2);
        font-size: clamp(12px, 1.5vw, 20px);
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    ul li {
      width: 50%;
    }
  }

  @media (max-width: 480px) {
    ul li {
      &:nth-of-type(odd) {
        width: 48%;
      }
    }
  }
}

.infoRight {
  width: 45%;
  display: flex;
  justify-content: flex-end;
  gap: 2px;
  margin-top: 30px;

  .moreInfoMap {
    width: 198px;
    height: 106px;
  }

  h3 {
    white-space: nowrap;
    font-size: clamp(18px, 1.8vw, 39px);
    font-weight: 700;
  }

  h4 {
    white-space: nowrap;
    color: var(--text_color2);
    font-size: clamp(14px, 1.5vw, 20px);
    padding-left: 5px;
  }

  img {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-top: 25px;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .moreInfoMap {
      width: 160px;
      height: 80px;
    }
  }
}

.fullScreenSlider {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  background: #000;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  * {
    user-select: none;
  }

  .closeFull {
    position: absolute;
    top: 0;
    right: 0;
    background: var(--bg_color4);
    color: #fff;
    width: 50px;
    height: 50px;
    font-size: 30px;
    cursor: pointer;
    border-radius: 2px;
    opacity: 0.7;
    transition: 0.1s ease-in-out;
    z-index: 3;

    &:hover {
      opacity: 1;
    }

    @media (max-width: 768px) {
      width: 40px;
      height: 40px;
      font-size: 25px;
      opacity: 1;
    }
  }

  div[class*='arrow-left2'],
  div[class*='arrow-right2'] {
    position: absolute;
    top: 40%;
    cursor: pointer;
    color: #fff;
    z-index: 1;
    font-size: 90px;
    font-family: monospace;
    filter: drop-shadow(0 0 3px #00000023);
    transition: 0.1s ease-in-out;
    user-select: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  div[class*='arrow-left2'] {
    left: 2%;
    transform: rotate(-90deg);
  }

  div[class*='arrow-right2'] {
    right: 2%;
    transform: rotate(90deg);
  }

  div[class*='swiper-button-disabled'] {
    opacity: 0.2;
    cursor: default;
  }
}

.slider2 {
  width: 1280px;
  height: 853px;
  max-width: 85%;
  max-height: 80%;
  margin: auto;

  div[class*='swiper-slide'] {
    min-height: 100% !important;
  }

  @media (max-width: 768px) {
    max-width: 95%;
    max-height: auto;
    height: auto;
    aspect-ratio: 1.6;
  }
}

.slider3 {
  width: 98%;
  height: 10%;
  padding-bottom: 20px;

  > div {
    width: max-content !important;
    margin: 0 auto;
  }

  .slide3 {
    cursor: pointer;
    opacity: 0.7;
    border-radius: 5px;
    overflow: hidden;
  }

  div[class*='swiper-slide-thumb-active'] {
    opacity: 1;
  }
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3b4144a9;

  .modal {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 90%;
    max-width: 1440px;
    height: 95%;
    border-radius: 5px;
    background: var(--bg_color1);
  }

  .header {
    width: 100%;
    height: 78px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-inline: 20px;
    gap: 8px 20px;
    padding-top: 10px;

    > span {
      background: #f7505c;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      width: 105px;
      height: 28px;
    }

    button {
      font-size: 20px;
      cursor: pointer;
      font-weight: 700;
      border: 1px solid transparent;
      width: 105px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-radius: 5px;
      transition: 0.1s ease-in-out;
      color: var(--text_color1);

      &[class*='active'] {
        color: #f7505c;
        border-color: #f7505c;
      }

      &:last-of-type {
        width: 125px;
        margin-inline: auto 20px;
        border: 1px solid #1a1a1a;

        span {
          font-size: 16px;
          font-weight: 500;
        }

        svg {
          width: 22px;
          height: 25px;
        }
      }
    }

    @media (max-width: 768px) {
      button:last-of-type {
        margin-inline: auto 0;
      }
    }
  }

  .leftSide {
    width: 70%;
    height: calc(100% - 100px);
    margin-top: auto;

    div[class*='map'] {
      width: 100%;
      height: 100%;
    }

    .infoModalSlider {
      width: 100%;
      height: 100%;
      background: #000;
      padding: 10px;
      align-items: center;
      display: flex;
      flex-direction: column;

      div[class*='swiper']:first-of-type {
        margin-block: auto;
      }

      span[class*='id'],
      div[class*='heartIcon'] {
        display: none !important;
      }
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .rightSide {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 70px;

    h3 {
      font-size: 17px;
      padding-inline: 30px;
      color: var(--text_color1);
      margin-bottom: 20px;
    }

    form {
      width: 100%;
      padding-inline: 30px;

      button {
        margin-left: 0;
      }
    }

    .authorBlock {
      width: 100%;
      margin-top: auto;
      padding: 20px;
      display: flex;
      align-items: flex-start;
      background: var(--bg_color2);

      img {
        pointer-events: none;
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: 10px;

        li {
          font-size: 18px;

          &:first-of-type {
            font-weight: 700;
            font-size: clamp(18px, 1.5vw, 20px);
          }
        }
      }
    }

    .shareBlock {
      width: 100%;
      height: 45px;
      border-top: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      padding-inline: 15px;
      background: var(--bg_color2);

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 20px;
        cursor: pointer;
        margin-left: 10px;

        &:last-of-type {
          height: 25px;
        }

        a {
          width: 100%;
          height: 100%;
        }

        span {
          color: #ff0000;
          font-weight: bold;
          cursor: default;
          font-size: 20px;
        }
      }

      svg {
        width: 100%;
        height: 100%;

        &:hover path {
          fill: #ff0000 !important;
        }
      }
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(35%, -35%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    color: #000;
    transition: 0.1s ease-in-out;
    font-size: 16px;
    font-family: cursive;
    box-shadow: 0 0 5px 1px #535353;

    &:hover {
      box-shadow: 0 0 7px 1px #535353;
    }
  }
}

.similar {
  width: 100%;
  max-width: 1440px;
  margin-block: 70px;
  padding-inline: 16px;
  margin: 0 auto;

  h3 {
    font-weight: 500;
    font-size: 22px;
    margin-bottom: 16px;
  }

  @media (max-width: 1024px) {
    a:last-of-type {
      display: none;
    }
  }
}
