.map {
  width: 100%;
  height: 400px;

  ymaps:active {
    cursor: default !important;
  }

  &[class*='hide'] {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 768px) {
    height: 250px;
  }
}
