.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 999;
  backdrop-filter: blur(8px);
  background: rgba(16, 24, 40, 0.8);

  .container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 98%;
    max-width: 430px;
    background: var(--bg_color1);
    border-radius: 12px;
    max-height: 100%;
    overflow-y: auto;
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 24px 24px 0;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px 0;
    padding: 24px;

    input {
      display: flex;
      align-items: center;
      width: 100%;
      height: 44px;
      padding-inline: 10px;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      outline: none;
      border: 1px solid #d0d5dd;
      background: #fff !important;
      font-size: 16px;
      color: #3b4144;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        width: 48%;
      }
    }

    textarea {
      width: 100%;
      height: 110px;
      padding: 5px 10px;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      outline: none;
      border: 1px solid #d0d5dd;
      background: #fff !important;
      font-size: 16px;
      color: #000;
      resize: none;
    }

    button[type='submit'] {
      width: 180px;
      height: 36px;
      color: #fff;
      background: #037e8c;
      cursor: pointer;
      transition: 0.1s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      font-size: 16px;

      &:hover {
        background: var(--text_color1);
        color: var(--text_color6);
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .bottom {
    width: 100%;
    display: flex;
    padding: 10px;
    background: #f1f1f1;

    > div {
      width: 100%;
      display: flex;
      gap: 10px;

      ul {
        display: flex;
        flex-direction: column;
        gap: 5px !important;

        li {
          font-size: 18px;
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 50%;
    opacity: 0.9;
    color: #fff;
    transition: 0.1s ease-in-out;
    font-size: 16px;
    font-family: cursive;

    &:hover {
      opacity: 1;
      box-shadow: 0 0 5px 1px #b4b4b4;
    }
  }
}
