.nav {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 5%;

  li {
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 500;
    font-size: 16px;

    &:hover .subMenu {
      display: block;
    }
  }

  > div {
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    height: 100%;
    user-select: none;
    transition: 0.1s ease-in-out;

    &:hover .subMenu {
      display: block;
    }

    span {
      padding: 8px 10px 10px 10px;
      border-radius: 5px;
      font-weight: 500;
      transition: 0.1s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;
      background: var(--bg_color7);

      &:hover {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      svg {
        transition: 0.2s;
        transform: translateY(1px);

        path {
          fill: var(--text_color3);
        }
      }
    }

    &:hover {
      span {
        background: #037e8c;
        color: #fff;

        svg {
          transform: translateY(3px);

          path {
            fill: #fff;
          }
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    padding-inline: 10px;
  }

  .subMenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background: var(--bg_color1);
    width: max-content;
    border: 1px solid #cdd1d4;
    border-radius: 5px;
    border-top-left-radius: 0;
    overflow: hidden;
    padding-block: 5px;

    li {
      display: flex;
      align-items: center;
      height: 36px;
      transition: 0.1s ease-in-out;

      &:hover a {
        color: #fff;
        background: #037e8c;
      }
    }
  }
}

.rightBlock {
  height: 100%;
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;

  > div:not(.menu),
  > a,
  > button {
    border-radius: 5px;
    border: 1px solid var(--hide_border);
    padding-inline: 10px;
    height: 36px;
    display: flex;
    align-items: center;
    transition: 0.1s ease-in-out;
    background: var(--bg_color7);
  }

  .phone {
    &:hover {
      background: #037e8c;

      path {
        fill: #fff;
      }

      span {
        color: #fff;
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }

    span {
      font-size: 16px;
    }
  }

  .languageSelect {
    width: 120px;
    position: relative;
    padding-inline: 10px;
    cursor: default;
    gap: 8px;

    &:hover {
      > span svg {
        transform: translateY(3px);

        path {
          fill: #fff;
        }
      }
    }

    > span {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      svg {
        transition: 0.2s;
        transform: translateY(1px);

        path {
          fill: var(--text_color3);
        }
      }
    }

    &:hover {
      background: #037e8c;

      .dropDown {
        visibility: visible;
        opacity: 1;
      }

      > span {
        color: #fff;
      }
    }

    .dropDown {
      visibility: hidden;
      opacity: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 10;
    }

    .dropDown {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 160px;
      height: 160px;
      position: absolute;
      top: 120%;
      right: 0;
      border: 1px solid #cdd1d4;
      padding: 15px;
      border-radius: 5px;
      background: var(--bg_color1);
      transition: 0.1s ease-in-out;

      span:not(.languageLabel) {
        width: 100%;
        text-align: left;
        font-size: 13px;
        padding-bottom: 3px;
      }

      div {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .languageLabel {
          position: relative;
          cursor: pointer;
          border: 1px solid #cdd1d4;
          border-radius: 5px;
          padding: 5px 8px;
          transition: 0.1s ease-in-out;
          user-select: none;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background: var(--bg_color3);

            svg {
              transform: translateY(3px);
            }
          }

          svg {
            transition: 0.2s;
            transform: translateY(1px);

            path {
              fill: var(--text_color3);
            }
          }
        }

        ul {
          position: absolute;
          width: 100%;
          background: var(--bg_color1);
          top: 105%;
          left: 0;
          border: 1px solid #cdd1d4 !important;
          outline: none;
          z-index: 10;

          li {
            cursor: pointer;
            height: 28px;
            display: flex;
            align-items: center;
            padding: 5px 8px;

            &:hover {
              background: #037e8c;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .add {
    background: #f75864;
    transition: 0.1s ease-in-out;
    cursor: pointer;
    user-select: none;
    border: none;

    &:hover {
      background: #037e8c;

      b {
        color: #037e8c;
      }
    }

    b {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      color: #f75864;
      border-radius: 50%;
      font-size: 20px;
      transition: 0.1s ease-in-out;
    }

    span {
      color: #fff;
      margin-left: 5px;
      text-shadow: 0 0 1px #000;
      font-size: clamp(13px, 2vw, 15px);
    }

    @media (max-width: 480px) {
      b {
        width: 18px;
        height: 18px;
      }
    }
  }

  .menu {
    height: 100%;
    display: flex;
    align-items: center;
    border: none;

    .tabletNav,
    .mobileTopBlock {
      display: none;
    }

    button {
      width: 36px;
      height: 36px;
      cursor: pointer;
      transition: 0.1s ease-in-out;
      border-radius: 5px;
      border: 1px solid var(--hide_border);
      padding-inline: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: var(--bg_color7);

      > span {
        display: block;
        height: 5px;
        font-weight: bold;
        user-select: none;
        font-size: 18px;
        transition: 0.1s ease-in-out;
        transform: translateY(-2px);
      }
    }

    &:hover {
      > button {
        background: #037e8c;
      }

      button span {
        color: #fff;
      }
    }

    > ul {
      display: none;
      flex-direction: column;
      align-items: flex-start;
      position: absolute;
      top: 90%;
      right: 15px;
      background: var(--bg_color1);
      width: max-content;
      border: 1px solid #cdd1d4;
      border-radius: 5px;

      &[data-open='true'] {
        display: flex;
      }

      li {
        width: 100%;
        display: flex;
        align-items: center;
        height: 36px;
        transition: 0.1s ease-in-out;
        cursor: pointer;

        a:not(.phone),
        span {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          font-weight: 500;
          padding-inline: 8px;
        }

        &:not(.mobileTopBlock):hover > span,
        &:not(.mobileTopBlock):hover > a {
          color: #fff;
          background: #037e8c;
        }
      }
    }
  }

  @media (min-width: 1100px) {
    .menu {
      position: relative;

      > ul {
        right: 0;
        top: 110%;
      }
    }
  }

  @media (max-width: 1100px) {
    .menu {
      > ul {
        padding: 15px;
      }

      .tabletNav {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        overflow: hidden;

        svg path {
          fill: var(--text_color3);
        }

        &[data-open='true'] {
          ul {
            max-height: 500px;
          }

          span {
            user-select: none;
            color: #fff;
            background: #037e8c;

            svg {
              transform: translateY(2px);

              path {
                fill: #fff;
              }
            }
          }
        }

        &:hover {
          span svg {
            transform: translateY(2px);

            path {
              fill: #fff;
            }
          }
        }

        span {
          display: flex;
          align-items: center;
          gap: 10px;
          height: 36px;

          svg {
            transition: 0.2s;
          }
        }

        ul {
          max-height: 0;
          transition: 0.1s ease-in-out;

          a {
            padding-left: 20px !important;
          }
        }
      }

      ul {
        right: 0;
        top: 100%;
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    > .languageSelect,
    > .phone {
      display: none !important;
    }

    > button {
      padding-inline: 6px;
    }

    .menu {
      .mobileTopBlock {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;
        height: 85px;
        gap: 8px;

        .phone {
          border-radius: 5px;
          border: 1px solid #cdd1d4;
          padding-inline: 10px;
          height: 36px;
          display: flex;
          align-items: center;
          transition: 0.1s ease-in-out;

          span {
            transition: 0.1s ease-in-out;
            padding-inline: 3px;
          }
        }

        .add {
          display: none;
          width: max-content;
          flex-direction: row;
          align-items: center;
          background: #f75864;
          height: max-content;
          border-radius: 5px;
          height: 36px;

          &:hover {
            background: #037e8c;
          }

          span {
            width: max-content;
            height: max-content;
            padding-top: 3px;
          }

          @media (max-width: 480px) {
            display: flex;
          }
        }

        .languageSelect {
          width: 135px;
          border-radius: 5px;
          border: 1px solid #cdd1d4;
          padding-inline: 10px;
          height: 36px;
          display: flex;
          align-items: center;
          transition: 0.1s ease-in-out;

          &:hover > span {
            background: #037e8c;
            color: #fff;
          }

          .dropDown > div {
            width: 100%;
          }

          span {
            transition: 0.1s ease-in-out;
            color: var(--text_color1);
            font-size: 16px;
          }
        }
      }
    }
  }

  @media (max-width: 480px) {
    gap: 5px;

    > .add,
    > .languageSelect {
      display: none !important;
    }

    .languageSelect {
      width: auto !important;

      &:hover {
        span svg {
          transform: translateY(3px) translateX(8px);
        }
      }

      > span {
        padding-inline: 0 8px !important;

        svg {
          transform: translateX(8px);
        }
      }
    }

    > div,
    > a,
    > button {
      padding-inline: 5px;
      height: 32px;
    }

    .menu button:not(.add) {
      width: 30px;
      height: 30px;

      span {
        transform: translateY(-5px);
      }
    }
  }
}

.userBlock {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  border: none !important;
  position: relative;

  ul {
    position: absolute;
    top: calc(100% + 11px);
    right: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: var(--bg_color1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);

    li {
      padding: 10px 12px;
      transition: 0.1s ease-in-out;
      display: flex;
      align-items: center;
      gap: 8px;

      svg {
        width: 21px;
      }

      &:last-of-type {
        border-top: 1px solid var(--bg_color2);
      }

      a {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &:hover {
        background: var(--bg_color2);
      }
    }
  }

  span {
    font-size: 14px;
    user-select: none;
    font-weight: bold;
    width: max-content;
  }

  @media (max-width: 768px) {
    > span {
      display: none;
    }
  }

  @media (max-width: 480px) {
    ul {
      position: fixed;
      width: 100%;
      top: 58px;
    }
  }
}
