.container {
  padding: 21px 16px;

  h1 {
    font-size: 22px;
    font-weight: 500;
  }

  .getCount {
    display: flex;
    font-size: 20px;
    color: #f7505c;
    font-weight: 500;
    margin-block: 5px 20px;
  }
}
