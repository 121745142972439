.container {
  width: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 58px;
  margin: 0 auto;

  > section {
    width: 100%;
    margin: 0 auto;
    max-width: 1440px;
  }

  &[class*='filter'] {
    padding-top: 150px;
  }
}
