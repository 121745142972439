.aboutUs {
  width: 100%;
  background: var(--bg_color2);
  padding: 120px 20%;

  > div {
    display: flex;
    align-items: flex-start;
    gap: 32px;
  }

  .info {
    width: 50%;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: clamp(16px, 1.7vw, 23px);
      font-weight: 700;
      padding-bottom: 12px;
      color: var(--text_color3);
    }

    p {
      font-size: clamp(14px, 1.5vw, 18px);
      line-height: 150%;
      color: var(--text_color3);
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 3%;
  }

  @media (max-width: 768px) {
    > div {
      flex-direction: column;

      form {
        width: 100%;

        button {
          width: 50%;
          margin: 0 auto;
        }
      }
    }

    .info {
      width: 100%;
    }
  }
}
