.loginModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 999;
  backdrop-filter: blur(8px);
  background: rgba(16, 24, 40, 0.8);

  .modalIcon {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid #f2f4f7;
    outline: 8px solid #f9fafb;
    background: #f2f4f7;
    border-radius: 50%;
  }

  .container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 560px;
    background: var(--bg_color1);
    border-radius: 12px;
    padding: 24px;
    max-height: 100%;
    overflow-y: auto;

    > div {
      display: flex;
      flex-direction: column;

      div:first-of-type {
        display: flex;
        align-items: center;
        gap: 30px;

        span {
          font-weight: 500;
          color: var(--text_color3);
          font-size: 20px;
        }
      }

      > p {
        color: var(--text_color3);
        font-size: 15px;
        margin-block: 20px;
      }
    }

    @media (max-width: 768px) {
      max-width: 95%;
      min-width: 95%;
      width: 95%;
    }
  }

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000;
    border-radius: 50%;
    opacity: 0.9;
    color: #fff;
    transition: 0.1s ease-in-out;
    font-size: 16px;
    font-family: cursive;

    &:hover {
      opacity: 1;
      box-shadow: 0 0 5px 1px #b4b4b4;
    }
  }

  .login {
    form > p {
      cursor: pointer;
      margin-bottom: 12px;
      color: #f75864;
      font-weight: 500;
    }

    .goToRegister {
      color: var(--text_color3);
      font-size: 17px;

      b {
        color: #f75864;
        cursor: pointer;
        padding-left: 5px;
      }
    }
  }

  .register {
    label:nth-of-type(1),
    label:nth-of-type(2) {
      width: calc(50% - 10px);
    }
  }

  .SignInWith {
    display: flex;
    align-items: center;
    width: 100%;
    height: 42px;
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding-inline: 14px;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    margin-bottom: 7px;
    border: 1px solid #037e8c;
    background: var(--bg_color8);

    span {
      margin: 0 auto;
      color: #037e8c;
    }

    &:hover {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
      background: #037e8c;

      span {
        color: #fff;
      }

      img {
        filter: drop-shadow(0px 0px 1px #ffffff75);
      }
    }
  }

  .orSignIn {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    h3 {
      padding-inline: 5px;
      position: relative;
      width: max-content;
      white-space: nowrap;
      text-align: center;
      color: var(--text_color5);
      font-size: 15px;
    }

    span {
      width: 100%;
      height: 1px;
      background: #e4e7ec;
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    margin: 4px 0 36px;

    label {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 8px;

      span {
        color: var(--text_color5);
        font-size: 15px;
      }

      b {
        color: red;
      }

      input {
        display: flex;
        align-items: center;
        width: 100%;
        height: 44px;
        padding-inline: 10px;
        border-radius: 12px;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        outline: none;
        border: 1px solid #d0d5dd;
        background: #fff !important;
        font-size: 16px;
        color: #3b4144;
      }

      .passEye {
        position: absolute;
        right: 15px;
        top: 58%;
        cursor: pointer;

        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(30deg);
          color: #a9a9a9;
          font-weight: 600;
          font-size: 21px;
        }
      }
    }

    button[type='submit'] {
      width: 100%;
      height: 46px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: #037e8c;
      border: 1px solid #037e8c;
      border-radius: 8px;
      font-size: 17px;
      cursor: pointer;
      transition: 0.1s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        background: transparent;
        color: #037e8c;
      }

      &:disabled {
        cursor: default;
        opacity: 0.8;
      }
    }
  }
}
