.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 16px;
  margin: 21px auto;
  width: 100%;
  max-width: 1440px;

  h1 {
    width: 100%;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  > nav {
    margin-inline: auto;
  }
}
