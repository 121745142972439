.container {
  display: flex;
  justify-content: space-between;
  padding-inline: 15px;
  width: 100%;
  max-width: 1440px;
  margin: 25px auto;

  h1,
  h2,
  span,
  p {
    color: var(--text_color7);
  }

  h1 {
    font-weight: 600;
    font-size: 24px;
  }

  .leftSide {
    display: flex;
    flex-direction: column;
    width: 72%;

    > p {
      font-size: 16px;
      margin-top: 16px;
    }
  }

  .rightSide {
    width: 25%;

    > div {
      position: sticky;
      top: 65px;
      flex-direction: column;

      a {
        width: 100%;
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-block: 20px;

    .map {
      margin-bottom: 14px;
    }

    .phone {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0 10px;

      > button {
        width: 36px;
        height: 36px;
        border-radius: 5px;
        background: #037e8c;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        opacity: 0.8;
        transition: 0.1s ease-in-out;
        color: #fff;
        font-size: 20px;

        &:hover {
          opacity: 1;
          transform: scale(1.02);
        }
      }
    }

    .infoBlock {
      > input,
      > div {
        margin-bottom: 10px;
      }
    }

    .standartBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      margin-bottom: 14px;

      label {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .select {
      position: relative;
      height: max-content;

      * {
        user-select: none;
      }

      ul {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        max-width: 360px;
        border: 1px solid #ced4da;
        border-radius: 4px;
        z-index: 10;
        box-shadow: 0 0 5px #0000001a;
        display: flex;
        flex-direction: column;
        max-height: 200px;
        overflow: auto;
        background: var(--bg_color1);

        li {
          cursor: pointer;
          padding: 5px 10px;

          &:hover {
            background: var(--bg_color9);
          }
        }
      }
    }

    .selectBtn {
      width: 100%;
      height: 36px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-inline: 10px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      cursor: pointer;
      transition: 0.1s ease-in-out;

      > span {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      svg {
        min-width: 10%;
        display: block;
        transition: 0.2s ease-in-out;

        path {
          fill: var(--text_color3);
        }
      }

      &:hover {
        background: var(--bg_color9);

        svg {
          transform: translateY(3px);
        }
      }
    }

    h2 {
      width: 100%;
      margin-bottom: 10px;
      user-select: none;
      font-size: clamp(16px, 3vw, 22px);
    }

    input:not([type='radio']),
    textarea {
      width: 100%;
      height: 36px;
      padding: 5px;
      max-width: 285px;
      border: 1px solid #ced4da;
      border-radius: 4px;
      outline: none;
      color: #3b4144;
      resize: none;
    }

    label {
      cursor: pointer;
    }

    input[type='radio'],
    input[type='checkbox'] {
      accent-color: #f7505c;
      pointer-events: none;
      border-radius: 5px;
      width: 16px;
      height: 16px;
    }

    .regions {
      display: flex;
      align-items: center;
      gap: 0 10px;
      width: 100%;
      max-width: 360px;
      margin-bottom: 14px;

      .select {
        width: 40%;

        &:nth-of-type(2) {
          width: 60%;
        }
      }
    }

    .address {
      margin-bottom: 14px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0 10px;

      input {
        max-width: 220px;
      }
    }

    .priceBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      margin-bottom: 14px;

      > input {
        width: 65%;
      }

      .select {
        width: 8%;
        min-width: 75px;

        span {
          display: flex;
          align-items: center;
        }
      }
    }

    .floorBlock {
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      margin-bottom: 14px;

      > div {
        width: 15%;
        min-width: 130px;
      }
    }

    .area {
      margin-bottom: 14px;
    }

    .images {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 0 10px;
      margin-bottom: 10px;

      > div {
        position: relative;

        button {
          display: none;
          width: 18px;
          height: 18px;
          position: absolute;
          top: 3px;
          right: 3px;
          cursor: pointer;
          background: #f7505c;
          color: #fff;
          border-radius: 50%;
          font-weight: bold;
          font-size: 12px;
          align-items: center;
          justify-content: center;
          font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        }

        &:hover {
          button {
            display: flex;
          }
        }
      }

      img {
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .uploadBtn {
      width: auto;
      height: 36px;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      cursor: pointer;
      background: #41536b;
      display: flex;
      align-items: center;
      border-radius: 5px;
      padding: 5px 10px;

      span {
        color: #ced4da;
      }

      input {
        width: 220px;
        height: 36px;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;
      }
    }
  }
  .additionalBlock {
    width: 100%;

    textarea {
      width: 100%;
      height: 290px;
      padding: 10px;
      max-width: 700px;
    }
  }

  .agreeWithTerms {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 14px;

    span {
      user-select: none;
    }

    a {
      text-decoration: underline;
      user-select: none;
    }
  }

  button[type='submit'] {
    margin: 20px 0;

    &:disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }

  @media (max-width: 1024px) {
    .rightSide {
      display: none;
    }

    .leftSide {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .address,
    .standartBlock {
      flex-direction: column;
      align-items: flex-start !important;

      input[type='text'],
      label {
        margin-bottom: 10px;
      }
    }
  }
}
