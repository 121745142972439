.container {
  width: 95%;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
  padding-top: 30px;

  h2,
  p,
  span,
  b {
    font-size: 18px;
  }

  .leftBlock {
    width: 74%;
    display: flex;
    flex-direction: column;

    form {
      margin-inline: auto;
    }

    @media (max-width: 1024px) {
      width: 100%;
    }
  }

  .rightBlock {
    width: 26%;
    height: auto;
    padding-left: 20px;
    padding-top: 40px;

    > div {
      position: sticky;
      top: 80px;
      gap: 10px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  h1 {
    font-size: 22px;
    font-weight: 500;
    height: 40px;
  }

  .info {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-block: 20px 120px;

    .email {
      padding-left: 40%;
      text-align: right;
    }

    .block1 {
      width: 40%;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    .block2 {
      width: 60%;
      display: flex;
      flex-wrap: wrap;
      height: max-content;

      b {
        width: 90px;
      }

      span:nth-of-type(2) {
        width: 100%;
        margin-left: 90px;
      }

      span {
        margin-bottom: 5px;
      }
    }

    h2 {
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
    }

    > span {
      width: 100%;
    }

    .aboutUs {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-top: 24px;

      h3 {
        font-weight: 700;
        font-size: 20px;
      }

      p {
        line-height: 25px;
      }
    }

    @media (max-width: 768px) {
      > div {
        flex-direction: column;

        .email {
          padding-left: 0;
          text-align: left;
        }

        .block2 {
          margin-block: 5px;
        }
      }
    }
  }
}
