.favoriteModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 999;
  backdrop-filter: blur(8px);
  background: rgba(16, 24, 40, 0.8);

  .container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 560px;
    min-height: 300px;
    background: var(--bg_color1);
    border-radius: 12px;
    padding: 24px;
    max-height: 100%;
    overflow-y: auto;

    .close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #000;
      border-radius: 50%;
      opacity: 0.9;
      color: #fff;
      transition: 0.1s ease-in-out;
      font-size: 16px;
      font-family: cursive;

      &:hover {
        opacity: 1;
        box-shadow: 0 0 5px 1px #b4b4b4;
      }
    }
  }
}
