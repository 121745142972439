.form {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 11px 0;

  input {
    padding: 7px 12px;
    border-radius: 5px;
    width: 48%;
    outline: none;
    border: 1px solid #cdd1d4;
    box-shadow: 0px 1px 1px 0px #00000029;
    font-size: 16px;
    color: #3b4144;
  }

  textarea {
    width: 100%;
    padding: 7px 12px;
    border-radius: 5px;
    outline: none;
    resize: none;
    height: 176px;
    border: 1px solid #cdd1d4;
    box-shadow: 0px 1px 1px 0px #00000029;
    font-size: 16px;
    color: #3b4144;
  }

  button {
    width: 180px;
    height: 36px;
    color: #fff;
    background: #037e8c;
    cursor: pointer;
    transition: 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    margin-left: auto;

    &:hover {
      background: var(--text_color1);
      color: var(--text_color6);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    }
  }
}
