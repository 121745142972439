.container {
  display: flex;
  flex-direction: column;
  margin-block: 25px;
  padding-inline: 15px;

  h1 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 25px;
  }

  h2 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 17px;
  }

  p {
    margin-bottom: 25px;
    font-size: 16px;
    line-height: 140%;

    span {
      font-weight: 600;
      text-decoration: underline;
    }
  }

  b {
    font-weight: 600;
  }

  .bold {
    font-weight: 600;
  }
}
