.pagination {
  display: flex;
  justify-content: center;
  margin-top: 40px;

  > ul {
    gap: 7px 0;
  }

  button {
    height: 36px;
    width: 57px;
    font-weight: bold;
    transition: background-color 1s 60s linear !important;
    color: var(--text_color1);
    border-color: var(--text_color1);
  }

  div {
    color: var(--text_color1);
  }

  button[class*='Mui-selected'] {
    background: #f7505c;
    border-color: #f7505c;
    color: #fff;
  }
}
