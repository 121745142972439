.section {
  width: 100%;
  height: auto;
  padding: 0 15px;
  margin: 60px auto !important;

  h2 {
    padding-bottom: 13px;
    font-size: 22px;
    font-weight: 500;
  }
}
