.footerNav {
  width: 100%;
  background: var(--bg_color3);
  padding-block: 25px;

  > div {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
  }

  .logo {
    width: 182px;
    height: 52px;
  }

  @media (max-width: 1024px) {
    > div {
      padding: 22px 3%;
    }

    .logo {
      width: 120px;
      height: 52px;
    }
  }

  @media (max-width: 768px) {
    > div {
      flex-direction: column;
    }
  }
}

.footerBottom {
  padding: 22px 10%;
  background: var(--bg_color6);

  > div {
    width: 100%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
  }

  span,
  a {
    font-size: clamp(14px, 1.5vw, 17px);
    line-height: 20px;
    color: #f1f1f1;
  }

  @media (max-width: 768px) {
    padding: 15px 2%;
  }
}

.footerMenu {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 10%;

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    min-width: 230px;

    li {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 17px;
      line-height: 25px;
      color: var(--text_color4);
      transition: 0.1s ease-in-out;

      a {
        color: var(--text_color3);
      }

      a:hover {
        color: var(--text_color1) !important;
      }

      &:first-of-type {
        font-size: 19px;
        font-weight: 700;
        padding-bottom: 6px;
        cursor: default;
        pointer-events: none;
      }

      svg {
        width: 25px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px 0;
    padding-left: 0;

    .phoneNumbers {
      max-width: 100%;
    }

    ul {
      width: 70%;

      &:nth-of-type(2) {
        width: 100%;
      }

      li {
        gap: 3px;
      }
    }
  }
}

.phoneNumbers {
  display: flex;
  align-items: flex-start !important;
  flex-wrap: wrap;
  max-width: 300px;

  b {
    padding-right: 7px;
  }

  p,
  > a {
    width: 100%;
    line-height: 140%;
  }

  div {
    display: flex;
    flex-direction: column;
  }
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5px;

  li {
    padding-bottom: 0 !important;
    cursor: pointer;
    pointer-events: all !important;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.contactUs {
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 3px solid #f7505c;
  border-radius: 15px;
  padding: 6px 10px;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 3px 5px 0px #0000001a;

  &:hover {
    box-shadow: 0px 3px 10px 0px #0000002a;
  }

  h3 {
    color: var(--text_color4);
    font-weight: 700;
    font-size: clamp(14px, 2vw, 23px);
  }

  a {
    color: var(--text_color4);
    font-weight: 700;
  }

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
